import React, {
  KeyboardEvent,
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  useCallback,
  useMemo,
  SyntheticEvent,
} from "react";
import CardReceiptGroupSettingUX from "react-lib/apps/HISV3/BIL/CardReceiptGroupSettingUX";
import CardReceiptGroupEditUX from "react-lib/apps/HISV3/BIL/CardReceiptGroupEditUX";
// Config
import CONFIG from "config/config";
import moment from "moment";
import { Button, Dropdown, Form, Modal } from "semantic-ui-react";
import { formatDate, formatUTCtoMoment } from "react-lib/utils/dateUtils";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModAuthen from "react-lib/apps/common/ModAuthen";

const CARD_RECEIPT_GROUP = "CardReceiptGroup";

// Types
type CardReceiptGroupSettingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;

  // CommonInterface
  errorMessage?: Record<string, any>;
  successMessage?: Record<string, any>;
  bilReceiptCodeDetail?: any;
  selectedDevice?: any;
  masterData?: any;
  buttonLoadCheck?: any;
  receiptGroupSetting?: Record<string, any>;
};

const CURRENT_YEAR = Number(moment().format("YYYY")) + 543;

// Images
export const IMAGES = {
  check: "static/images/transport/check.png",
  dismiss: "static/images/transport/dismiss.png",
};

const receiptGroupStatus = [
  { key: "CLOSED", value: "CLOSED", text: "ใช้งานหมดแล้ว" },
  {
    key: "ACTIVE",
    value: "ACTIVE",
    text: "กำลังใช้งาน",
  },
  { key: "INACTIVE", value: "INACTIVE", text: "ยังไม่พร้อมเปิดใช้งาน" },
];

const receiptEditField = [
  "date",
  "time",
  "prefix",
  "running_start",
  "running_end",
  "current_running",
  "status",
];

const returnReceiptOption = [
  {
    key: "เนื่องจากต้องการยกเลิก",
    text: "เนื่องจากต้องการยกเลิก",
    value: "เนื่องจากต้องการยกเลิก",
  },
  {
    key: "เนื่องจากว่าใบเสร็จติด",
    text: "เนื่องจากว่าใบเสร็จติด",
    value: "เนื่องจากว่าใบเสร็จติด",
  },
];

const CardReceiptGroupSetting = (props: CardReceiptGroupSettingProps) => {
  const intl = useIntl();
  const [openModReceiptEdit, setOpenModReceiptEdit] = useState<string>(""); // edit, new, ""
  const [checkReceiptField, setCheckReceiptField] = useState<string[]>([]);
  const [openModAuthen, setOpenModAuthen] = useState<boolean>(false);
  const [returnReceipt, setReturnReceipt] = useState<string>("");
  const [returnOption, setReturnOption] = useState<any>(returnReceiptOption);

  useEffect(() => {
    props.onEvent({ message: "GetReportStationSummary", params: { getReceiptPool: true } });
  }, []);

  // Mod
  const handleCloseModReceiptEdit = () => {
    props.setProp("receiptGroupSetting.selectData", {});
    props.setProp("errorMessage", {
      ...props.errorMessage,
      [`${CARD_RECEIPT_GROUP}_update`]: null,
    });
    setOpenModReceiptEdit("");
  };

  const handleOpenModReceiptEdit = useCallback((rowInfo: any) => {
    if (rowInfo?.type === "edit") {
      props.setProp("receiptGroupSetting.selectData", rowInfo);
      setOpenModReceiptEdit("edit");
      setCheckReceiptField([]);
    }
  }, []);

  const handleClickCheckExpenses = useCallback(
    (item: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      handleOpenModReceiptEdit({ ...item, type: "edit" });
    },
    [handleOpenModReceiptEdit]
  );

  // Use Memo
  const yearOptions = useMemo(() => {
    const years = Array(111)
      .fill("")
      .map((_, index) => CURRENT_YEAR - 100 + index);

    return years.map((year) => ({ key: year, value: year, text: year }));
  }, []);

  const stationName = useMemo(() => {
    const bilStation: Record<string, any>[] = props.masterData?.bilStation || [];

    const foundStation = bilStation.find((item) => item.device === props.selectedDevice?.id);
    let deviceName =
      (foundStation?.name || "").split(`${foundStation?.number}: `)?.[1] || foundStation?.name;

    return {
      ...foundStation,
      id: foundStation?.id,
      name: deviceName,
    };
  }, [props.masterData?.bilStation, props.selectedDevice]);

  const receiptGroupData = useMemo(() => {
    return (props.receiptGroupSetting?.receiptPoolData || [])
      ?.filter((item: any) => {
        return (
          (props.receiptGroupSetting?.budgetYear !== ""
            ? Number(moment(new Date(item?.receipt_requested_at))?.format("YYYY")) ===
              Number(props.receiptGroupSetting?.budgetYear) - 543
            : item) &&
          (props.receiptGroupSetting?.status !== "ทั้งหมด"
            ? props.receiptGroupSetting?.status === item?.status
            : item) &&
          (props.receiptGroupSetting?.date !== ""
            ? formatDate(formatUTCtoMoment(item?.receipt_requested_at)) ===
              props.receiptGroupSetting?.date
            : item)
        );
      })
      ?.map((item: any) => {
        let status = receiptGroupStatus.find((opt: any) => opt.value === item.status)?.text;
        let receiptDate = formatUTCtoMoment(item?.receipt_requested_at);
        let selectDate = formatDate(receiptDate);
        let selectTime = moment(item?.receipt_requested_at)?.format("HH:mm");

        return {
          ...item,
          isOn: (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img
                src={item.status === "ACTIVE" ? IMAGES.check : IMAGES.dismiss}
                style={{ width: "30px", height: "100%" }}
              ></img>
            </div>
          ),
          year: <div style={{ margin: "0px 15px" }}>{item.fiscal_year}</div>,
          user: <div style={{ margin: "0px 15px" }}>{item.create_user_name}</div>,
          date: (
            <div style={{ margin: "0px 15px" }}>
              {item.receipt_requested_at !== null ? `${selectDate} ${selectTime}` : "-"}
            </div>
          ),
          status: <div style={{ margin: "0px 15px" }}>{status}</div>,
          action: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="yellow"
                size="mini"
                onClick={handleClickCheckExpenses({
                  ...item,
                  date: item.receipt_requested_at !== null ? selectDate : "",
                  time: item.receipt_requested_at !== null ? selectTime : "",
                })}
              >
                แก้ไขข้อมูล
              </Button>
            </div>
          ),
        };
      });
  }, [props.receiptGroupSetting?.receiptPoolData, handleClickCheckExpenses]);

  const receiptGroupStatusAll = useMemo(() => {
    return [{ key: "ทั้งหมด", value: "ทั้งหมด", text: "ทั้งหมด" }, ...receiptGroupStatus];
  }, [receiptGroupStatus]);

  const handleChangeDataSearch = (_event: any, data: any) => {
    props.setProp(`receiptGroupSetting.${data.name}`, data.value);
  };

  const handleChangeDateSearch = (date: string) => {
    props.setProp("receiptGroupSetting.date", date);
  };

  const handleChangeDataEdit = (_event: any, data: any) => {
    props.setProp(`receiptGroupSetting.selectData.${data.name}`, data.value);
  };

  const handleChangeDateEdit = (date: string) => {
    props.setProp("receiptGroupSetting.selectData.date", date);
  };

  const handleChangeTimeEdit = (data: any) => {
    props.setProp("receiptGroupSetting.selectData.time", data);
  };

  const handleSearchReceipt = () => {
    props.onEvent({
      message: "GetReceiptPoolData",
      params: {
        buttonKey: `${CARD_RECEIPT_GROUP}_search`,
      },
    });
  };

  const handleUpdateReceiptPool = (username: any, password: any) => {
    let receiptEditData = receiptEditField.filter(
      (item: string) => !props.receiptGroupSetting?.selectData?.[item]
    );

    if (receiptEditData.length === 0) {
      setCheckReceiptField(receiptEditData);
      props.onEvent({
        message: "EditReceiptPool",
        params: {
          username,
          password,
          note: returnReceipt,
          station: stationName?.id,
          buttonKey: `${CARD_RECEIPT_GROUP}_update`,
          callback: () => {
            setOpenModReceiptEdit("");
          },
        },
      });
    } else {
      setCheckReceiptField(receiptEditData);
    }
  };

  const handleOpenModReceiptNew = () => {
    props.setProp("receiptGroupSetting.selectData.current_running", -1);
    setOpenModReceiptEdit("new");
    setCheckReceiptField([]);
  };

  const handleAdditionReturnReceipt = (e: any, { value }: any) => {
    let newReturnOption = [...returnOption, { text: value, value: value, key: value }];

    setReturnOption(newReturnOption);
  };

  const handleEditReceiptPool = () => {
    const selectReceiptPoolData = (props.receiptGroupSetting?.receiptPoolData || []).filter(
      (item: any) => item?.id === props.receiptGroupSetting?.selectData?.id
    )?.[0];
    const selectData = props.receiptGroupSetting?.selectData;

    const selectReceiptPoolDataObj = Object.keys(selectReceiptPoolData || []);
    const selectDataObj = Object.keys(selectData);

    const commonKeys = selectReceiptPoolDataObj.filter((key) => selectDataObj.includes(key));

    const checkEdit = commonKeys
      .filter((key: any) => key !== "status")
      .every((key: any) => {
        return selectReceiptPoolData[key] == selectData[key];
      });

    if (openModReceiptEdit === "edit" && !checkEdit) {
      setOpenModAuthen(true);
    } else {
      props.onEvent({
        message: "EditReceiptPool",
        params: {
          note: returnReceipt,
          station: stationName?.id,
          buttonKey: `${CARD_RECEIPT_GROUP}_update`,
          callback: () => {
            setOpenModReceiptEdit("");
          },
        },
      });
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <CardReceiptGroupSettingUX
        searchData={props.receiptGroupSetting}
        yearOptions={yearOptions}
        receiptGroupStatus={receiptGroupStatusAll}
        stationName={stationName}
        receiptGroupData={receiptGroupData}
        onChangeDataSearch={handleChangeDataSearch}
        onChangeDateSearch={handleChangeDateSearch}
        openModReceiptEdit={handleOpenModReceiptNew}
        onSearchReceipt={handleSearchReceipt}
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearchReceipt}
            // data
            paramKey={`${CARD_RECEIPT_GROUP}_search`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RECEIPT_GROUP}_search`]}
            // config
            title={intl.formatMessage({ id: "ค้นหา" })}
            color="blue"
            size="mini"
          />
        }
      />

      <Modal
        open={["new", "edit"].includes(openModReceiptEdit)}
        size="large"
        closeOnDimmerClick
        // callback
      >
        <CardReceiptGroupEditUX
          errorMessage={
            <div
              style={{
                ...(props.errorMessage?.[`${CARD_RECEIPT_GROUP}_update`] && {
                  marginBottom: "15px",
                }),
              }}
            >
              <ErrorMessage error={props.errorMessage?.[`${CARD_RECEIPT_GROUP}_update`]} />
            </div>
          }
          label={
            openModReceiptEdit === "new" ? "เพิ่มข้อมูลชุดใบเสร็จรับเงิน" : "แก้ไขข้อมูลชุดใบเสร็จ"
          }
          isEdit={openModReceiptEdit === "edit"}
          selectData={props.receiptGroupSetting?.selectData}
          receiptGroupStatus={receiptGroupStatus}
          stationName={stationName}
          receiptGroupData={receiptGroupData}
          checkReceiptField={checkReceiptField}
          closeModReceiptEdit={handleCloseModReceiptEdit}
          onChangeDataEdit={handleChangeDataEdit}
          onChangeDateEdit={handleChangeDateEdit}
          onChangeTimeEdit={handleChangeTimeEdit}
          onUpdateReceiptPool={handleUpdateReceiptPool}
          buttonUpdate={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleEditReceiptPool}
              // data
              paramKey={`${CARD_RECEIPT_GROUP}_update`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RECEIPT_GROUP}_update`]}
              // config
              title={openModReceiptEdit === "new" ? "บันทึก" : "บันทึกและแก้ไข"}
              color={openModReceiptEdit === "new" ? "green" : "yellow"}
              size="mini"
              fluid={true}
            />
          }
        />
      </Modal>

      <ModAuthen
        open={openModAuthen}
        titleColor={"blue"}
        titleName={"ระบุเหตุผลการ Return ใบเสร็จ"}
        children={
          <Form.Field style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <label>เหตุผลการ Return ใบเสร็จ</label>
              <Dropdown
                selection
                allowAdditions
                search
                options={returnOption}
                onAddItem={handleAdditionReturnReceipt}
                value={returnReceipt}
                onChange={(e: any, data: any) => {
                  setReturnReceipt(data.value);
                }}
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </div>
          </Form.Field>
        }
        onApprove={({ username, password }: any) => {
          // save
          handleUpdateReceiptPool(username, password);
          setOpenModAuthen(false);
        }}
        onDeny={() => {
          setOpenModAuthen(false);
        }}
      />
    </div>
  );
};

export default React.memo(CardReceiptGroupSetting);
